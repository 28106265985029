import React from "react"
import { Seo } from "../../components/seo"

const ThankyouEn = () => {
  return (
    <>
      <Seo title={`Mexico thankyou page`} language={"en"} />
      <section className="container thankyou-en english-page-body">
        <div className="row">
          <div className="en-pages">
            <p>Thank you so much for applying. We will respond ASAP.</p>
          </div>
        </div>
      </section>
    </>
  )
}

export default ThankyouEn
